<template>
  <app-form-view
    app="document_template"
    model="businesstype"
    api-url="doc-template/buztype/"
    :title="$t('menu.businessType')"
    :listRoute="{ name: 'businessType', query: $route.query }"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    :prepare-data="prepareData"
    v-model="formData"
  >
    <template v-slot="{ view }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="industryName"
            rules="required"
            type="select-server"
            :label="$t('fields.businessIndustry')"
            :view="view"
            :binds="{
              apiUrl: 'doc-template/industry/?active=true'
            }"
            v-model="formData.industry_id"
            ref="industry"
            @input="getBusinessCategoryItem"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveIndustryName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_industry_id"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="businessCategoryName"
            rules="required"
            type="select-server"
            :label="$t('fields.businessCategory')"
            :view="view"
            :binds="{
              apiUrl: buzCateUrl
            }"
            v-model="formData.business_cate_id"
            ref="businessCategory"
          />
          <app-input
            v-if="isSentToApprove"
            name="approvebusinessCategoryName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_business_cate_id"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.name')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="secondaryName"
            rules="required"
            :label="$t('fields.secondaryName')"
            :view="view"
            v-model="formData.secondary_name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveSecondaryName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_secondary_name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'

export default {
  name: 'business-type-form',
  components: {
    AppInput,
    AppFormView
  },
  data() {
    return {
      formData: {
        state: 'draft'
      },
      approveItems: [
        'approve_name',
        'approve_secondary_name',
        'approve_industry_id',
        'approve_business_cate_id'
      ],
      buzCateUrl: null
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    }
  },
  mounted() {
    const business_cate_id = this.$route.query.business_cate_id
    const industry_id = this.$route.query.industry_id
    if (business_cate_id && industry_id) {
      this.formData.business_cate_id = business_cate_id
      this.formData.industry_id = industry_id
      this.getData()
    }
  },
  methods: {
    prepareData(data) {
      if (data.business_cate_id?.id) {
        data.business_cate_id = data.business_cate_id.id
        data.industry_id = data.industry_id.id
      }
      return data
    },
    getData() {
      {
        this.$api({
          method: 'get',
          url:
            'doc-template/category/?id=' + this.$route.query.business_cate_id,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.formData.business_cate_id = data.results[0]
          this.formData.industry_id = data.results[0].industry_id
          this.formData = Object.assign({}, this.formData)
          this.$refs.industry.setDefaultServerItems(this.formData.industry_id)
          this.$refs.businessCategory.setDefaultServerItems(
            this.formData.business_cate_id
          )
        })
      }
    },
    getBusinessCategoryItem() {
      var url = ''
      if (
        this.formData.industry_id !== null &&
        this.formData.industry_id !== undefined
      ) {
        url =
          'doc-template/category/?active=true&industry_id=' +
          this.formData.industry_id.id
      } else {
        url = 'doc-template/category/?active=true'
      }
      this.buzCateUrl = url
    }
  }
}
</script>
